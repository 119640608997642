[
  {
    "name": "osmosis",
    "gasPrice": "0.0025uosmo",
    "ownerAddress": "osmovaloper1u5v0m74mql5nzfx2yh43s2tke4mvzghr6m2n5t",
    "default": true,
    "maxPerDay": 1
  },
  {
    "name": "juno",
    "gasPrice": "0.0025ujuno",
    "ownerAddress": "junovaloper19ur8r2l25qhsy9xvej5zgpuc5cpn6syydmwtrt",
    "autostake": {
      "batchTxs": 25
    }
  },
  {
    "name": "cosmoshub",
    "ownerAddress": "cosmosvaloper1a37ze3yrr2y9nn98l6frhjskmufvd40cpyd0gq",
    "gasPrice": "0.0025uatom"
  },
  {
    "name": "akash",
    "ownerAddress": "akashvaloper1xgnd8aach3vawsl38snpydkng2nv8a4kqgs8hf"
  },
  {
    "name": "chihuahua",
    "ownerAddress": "chihuahuavaloper19vwcee000fhazmpt4ultvnnkhfh23ppwxll8zz",
    "gasPrice": "1uhuahua",
    "gasPricePrefer": "1uhuahua"
  },
  {
    "name": "gravitybridge",
    "gasPrice": "0.025ugraviton"
  },
  {
    "name": "regen",
    "gasPrice": "0.03uregen",
    "ownerAddress": "regenvaloper1c4y3j05qx652rnxm5mg4yesqdkmhz2f6dl7hhk",
    "autostake": {
      "batchTxs": 5
    }
  },
  {
    "name": "terra",
    "gasPrice": "5.665uluna"
  },
  {
    "name": "terra2",
    "gasPrice": "0.015uluna"
  },
  {
    "name": "sentinel",
    "gasPrice": "0.02udvpn"
  },
  {
    "name": "dig",
    "gasPrice": "0.01udig",
    "ownerAddress": "digvaloper136avwnuvvy94dqmtnaue2nfvjes8xr37h9rzay"
  },
  {
    "name": "bitcanna",
    "gasPrice": "0.001ubcna"
  },
  {
    "name": "emoney",
    "gasPrice": "0.08ungm"
  },
  {
    "name": "kava",
    "gasPrice": "0.00008ukava"
  },
  {
    "name": "desmos",
    "gasPrice": "0.001udsm"
  },
  {
    "name": "cryptoorgchain",
    "gasPrice": "0.025basecro",
    "ownerAddress": "crocncl10mfs428fyntu296dgh5fmhvdzrr2stlaekcrp9"
  },
  {
    "name": "evmos",
    "txTimeout": 120000,
    "ownerAddress": "evmosvaloper1umk407eed7af6anvut6llg2zevnf0dn0feqqny",
    "maxPerDay": 1,
    "ledgerSupport": false
  },
  {
    "name": "sifchain",
    "gasPrice": "1500000000000rowan",
    "ownerAddress": "sifvaloper19t5nk5ceq5ga75epwdqhnupwg0v9339p096ydz"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze"
  },
  {
    "name": "comdex",
    "ownerAddress": "comdexvaloper17f70yjkvmvld379904jaddx9h0f74n32pjtmp6"
  },
  {
    "name": "cheqd",
    "gasPrice": "25ncheq"
  },
  {
    "name": "umee"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric"
  },
  {
    "name": "impacthub",
    "gasPrice": "0.025uixo",
    "gasPricePrefer": "0.1uixo"
  },
  {
    "name": "kichain",
    "gasPrice": "0.025uxki"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation",
    "image": "https://raw.githubusercontent.com/Konstellation/DARC_token/main/darctoken.svg"
  },
  {
    "name": "fetchhub",
    "gasPrice": "5000afet"
  },
  {
    "name": "cerberus",
    "gasPrice": "0.025ucrbrus",
    "autostake": {
      "batchTxs": 100
    },
    "ownerAddress": "cerberusvaloper1tat2cy3f9djtq9z7ly262sqngcarvaktr0w78f"
  },
  {
    "name": "secretnetwork",
    "gasPrice": "0.025uscrt",
    "gasPricePrefer": "0.05uscrt",
    "authzAminoSupport": true
  },
  {
    "name": "bostrom",
    "gasPrice": "0boot"
  },
  {
    "name": "starname",
    "gasPrice": "10uvoi"
  },
  {
    "name": "rizon",
    "gasPrice": "0.0001uatolo"
  },
  {
    "name": "decentr",
    "gasPrice": "0.025udec"
  },
  {
    "name": "assetmantle",
    "gasPrice": "0.025umntl",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    },
    "ownerAddress": "mantlevaloper1fqs7gakxdmujtk0qufdzth5pfyspus3yx394zd"
  },
  {
    "name": "crescent",
    "gasPrice": "0.025ucre",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    }
  },
  {
    "name": "meme",
    "gasPrice": "0.025umeme"
  },
  {
    "name": "cronos",
    "enabled": false
  },
  {
    "name": "harpoon",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca",
    "testnet": true
  },
  {
    "name": "kujira",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca"
  },
  {
    "name": "genesisl1",
    "txTimeout": 120000
  },
  {
    "name": "tgrade",
    "enabled": false
  },
  {
    "name": "aioz",
    "enabled": false
  },
  {
    "name": "echelon",
    "keplrFeatures": [
      "ibc-transfer",
      "ibc-go",
      "eth-address-gen",
      "eth-key-sign"
    ]
  },
  {
    "name": "kichaintestnet",
    "testnet": true
  },
  {
    "name": "likecoin",
    "gasPrice": "10nanolike"
  },
  {
    "name": "passage",
    "ownerAddress": "pasgvaloper196rujtjehu0dfc7y85lkcaps6tel76g3l9knjy"
  },
  {
    "name": "stride",
    "ownerAddress": "stridevaloper1x2kta40h5rnymtjn6ys7vk2d87xu7y6zfu9j3r",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    }
  },
  {
    "name": "pulsar",
    "testnet": true
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper1d5u07lhelk6lal44a0myvufurvsqk5d499h9hz",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    }
  },
  {
    "name": "rebus"
  },
  {
    "name": "jackal",
    "ownerAddress": "jklvaloper1rdyunvvqg2l3723hlyjvvsnkd4vg338uar8q2s"
  },
  {
    "name": "panacea",
    "gasPrice": "5umed",
    "gasPricePrefer": "5umed",
    "gasPriceStep": {
      "low": 0.5,
      "average": 1,
      "high": 1.5
    }
  }
]